.contactUs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contentLeft {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  h2 {
    font-size: 40px;
    margin-bottom: 10px;
  }
  p {
    line-height: 1.6;
  }
  .contentLeftText {
    margin-bottom: 15px;
  }
}
.contentRight {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 800px;
  .imageMock {
    position: relative;
    border-radius: 10px;
    width: 800px;
    height: 400px;
  }
}

@media (max-width: 820px) {
  .contactUs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .contentLeft {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    h2 {
      font-size: 40px;
      margin: 0 0 10px 0;
    }
    p {
      line-height: 1.6;
    }
    .contentLeftText {
      margin-bottom: 15px;
    }
  }
  .contentRight {
    .imageMock {
      width: 45vw;
      height: 45vw;
    }
  }
}


@media (max-width: 576px) {
  .contactUs {
    display: block;
    justify-content: space-between;
    align-items: center;
  }
  .contentLeft {
    max-width: 100%;
    h2 {
      font-size: 30px;
    }
  }
  .contentRight {
    display: block;
    .imageMock {
      width: 100%;
      height: 75vw;
    }
  }
}