.page-content-block-with-title {

  p {
    line-height: 24px;
    color: var(--color-grey2);
  }

  .page-content-block-with-title-has-text-icon {
    display: grid;
    grid-template-columns: min-content 1fr;
    width: 100%;

    img {
      margin-right: var(--padding-regular);
      transform: translateY(20px)
    }
  }

}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 1024px) {}

@media screen and (max-width: 820px) {}

@media screen and (max-width: 576px) {
  .page-content-block-with-title {

    .page-content-block-with-title-has-text-icon {
      svg {
        height: 20px;
        width: 20px;
        margin-right: var(--padding-small);
      }
    }

  }
}