.trustedBy {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-size: 40px;
    margin: 30px;
  }
  .companiesList {
    max-width: 960px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
.company {
  position: relative;
  width: 175px;
  height: 50px;
  margin: 20px;
  img {
    object-fit: contain;
    image-rendering: crisp-edges;
  }
}

@media (max-width: 820px) {
  .trustedBy {
    text-align: center;
  }
}


@media (max-width: 576px) {
  .trustedBy {
    align-items: center;
    h2 {
      font-size: 30px;
      margin: 30px;
    }
  }
}