.ReviewWrapper {
  width: 100%;
  .ReviewContent {
    display: flex;
    width: 100%;
    color: var(--color-grey6);
    justify-content: space-between;
    .ReviewContentLeft {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 560px;
      p {
        margin: 0px;
      }
      h2 {
        font-size: 36px;
        color: var(--color-grey1);
        margin: 0;
      }
      h3 {
        font-size: 16px;
        color: var(--color-grey1);
        padding: 10px 0px;
        margin: 0px;
      }
      .MockShopperIcon {
        position: relative;
        margin-top: 0px;
        height: 65px;
        width: 220px;
        border-radius: 5px;
        a {
          position: relative;
          height: 65px;
          width: 220px;
          display: block;
        }
      }
    }
    .ReviewContentRight {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .ReviewContentRightText {
        display: flex;
        max-width: 560px;
        flex-direction: column;
        p {
          line-height: 32px;
          margin: 10px 0 25px;
        }
        .ReviewDataBottomWrap {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          .ReviewDataBottom {
            p {
              margin: 0;
            }
            h4 {
              margin: 0;
              font-size: 18px;
            }
          }
          .starsWrap {
            display: flex;
            gap: 10px;

            img {
              filter: invert(100%) sepia(0%) saturate(1369%) hue-rotate(343deg) brightness(107%) contrast(73%);
              &.star-active {
                filter: invert(75%) sepia(20%) saturate(7466%) hue-rotate(343deg) brightness(101%) contrast(101%);
              }
            }
          }
        }
      }
      .Quotes {
        color: var(--color-primary);
        height: 42px;
        font-size: 82px;
        width: 36px;
        line-height: 1;
        transform: skewX(-20deg);
      }
    }
  }
}

@media (max-width: 576px) {
  .ReviewWrapper {
    .ReviewContent {
      display: block;
      .ReviewContentLeft {
        text-align: center;

        h2 {
          font-size: 30px;
        }
      }
    }
  }
}